<template>
    <Page
        :title="$t('sreq.time_in_out_logs.title')"
        :content-loading="loading"
    >
        <div
            v-if="logItems.length > 0"
            class="logs-table"
        >
            <div class="logs-table__header">
                {{ $t('sreq.time_in_out_logs.time_in') }}
            </div>
            <div class="logs-table__header">
                {{ $t('sreq.time_in_out_logs.time_out') }}
            </div>
            <div class="logs-table__header">
                {{ $t('sreq.time_in_out_logs.committed_by') }}
            </div>

            <template
                v-for="item in logItems"
                :key="item.id"
            >
                <div class="logs-table__cell">
                    <CommunityDateTimeDisplay
                        :input="item.inDate"
                        format="MM/DD/YYYY"
                        class="block mb-1"
                    />
                    <CommunityDateTimeDisplay
                        :input="item.inDate"
                        format="hh:mm a"
                    />
                </div>
                <div class="logs-table__cell pr-3">
                    <template v-if="item.outDate">
                        <CommunityDateTimeDisplay
                            :input="item.outDate"
                            format="MM/DD/YYYY"
                            class="block mb-1"
                        />
                        <CommunityDateTimeDisplay
                            :input="item.outDate"
                            format="hh:mm a"
                        />
                    </template>
                    <span
                        v-else
                        class="text-gray-450"
                    >
                        {{ $t('sreq.time_in_out_logs.not_specified_yet') }}
                    </span>
                </div>
                <div class="logs-table__cell">
                    <div
                        v-for="user in item.users"
                        :key="user.id"
                        class="pb-2"
                    >
                        {{ user.firstName }} {{ user.lastName }}
                    </div>
                </div>
            </template>
        </div>

        <div
            v-if="!loading && logItems.length === 0"
            class="flex flex-col items-center justify-center h-full"
        >
            <Icon
                name="empty-time-in-out-logs"
                class="mb-8"
            />
            <div class="text-md text-gray-400">
                {{ $t('sreq.time_in_out_logs.empty_placeholder') }}
            </div>
        </div>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import CommunityDateTimeDisplay from '@/components/ui/CommunityDateTimeDisplay';
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    components: { Icon, CommunityDateTimeDisplay, Page },

    mixins: [NotifyMixin],

    data() {
        return {
            loading: false,
            logItems: [],
        };
    },

    created() {
        this.loading = true;

        this.$sreqDataProvider
            .getTimeInOutLogs('serviceRequests', { id: this.$route.params.requestId })
            .then(response => {
                this.logItems = response;
            })
            .catch(error => {
                this.notifyError(error.message);
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
</script>

<style scoped>
.logs-table {
    @apply grid mt-2;
    grid-template-columns: 1fr 1fr 1.2fr;
}
.logs-table__header {
    @apply font-ffdin font-bold text-2xs uppercase pb-2 border-b border-black;
}
.logs-table__cell {
    @apply text-md font-normal py-4 border-b border-gray-200;
}
</style>
